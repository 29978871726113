import {AnalyticsList} from "../../../components/attendance/analytics/AnalyticsList";
import Search from "../../../components/commons/search";
import ViewList from "../../../components/commons/view-list";
import Button from "../../../components/commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {downloadFile, getAllQueryParams, getValueFromArray, setQueryParams} from "../../../utils/utils";
import {setModal, setSelectedRows} from "../../../reducers/attendanceReducer";
import {useFetch} from "../../../services/useFetch";
import {API_ATTENDANCE} from "../../../services/apiAttendance";
import moment from "moment/moment";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import DateRangePicker from "../../../components/commons/date-range-picker";
import {ExportModal} from "../../../components/commons/ExportModal";

export const AnalyticsListContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const {modal, selectedRows, totalData, attendanceList} = useSelector(state => state.attendanceReducer)
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: '',
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        order_by: "job_title",
        order_type: "desc",
        employee_ids: []

    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const {data: analyticsList, fetching: getAnalyticAttendanceApi, isLoading} = useFetch(API_ATTENDANCE.ANALYTICS_LIST)
    const { fetching: exportAnalyticAttendanceApi} = useFetch(API_ATTENDANCE.ANALYTICS_EXPORT)


    useEffect(() => {
        getAnalyticAttendanceList({...query})
    }, [location.search]);

    const getAnalyticAttendanceList = (item = query) => {
        getAnalyticAttendanceApi(item)
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params)
    }

    const modalVisibility = (value) => {
        dispatch(setModal({...modal, ...value}))
    }

    const onExport = () => {
        const payload = {
            ...query,
            employee_ids: getValueFromArray(selectedRows),
        }

        exportAnalyticAttendanceApi(payload, '', {}, 'blob')
            .then(res => {
                downloadFile(null, res, `Analytics-Attendance-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                modalVisibility({export: false, toast: true})
            })
    }

    return (
        <>
            <div className={"flex flex-row space-x-6 px-6 py-4"}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <div className={"flex flex-row gap-x-2"}>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by date:</p>
                        <DateRangePicker
                            value={query?.start_date ? [query?.start_date, query?.end_date] : []}
                            onChange={(value) => onChangeQuery({page: 1, start_date: value[0], end_date: value[1]})}
                            format='YYYY-MM-DD'
                            maxDate={`${moment().get('year')}-12-31`}
                        />
                    </div>
                    <ViewList
                        show={modal?.sort}
                        onShow={() => modalVisibility({sort: true})}
                        onClose={() => modalVisibility({sort: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                    <Button title="Export" icon={icFileUp} type="outline" disabled={selectedRows?.length === 0}
                            onClick={() => modalVisibility({export: true})}/>
                </div>
            </div>
            <AnalyticsList analyticsAttendanceList={analyticsList?.data}
                           query={query}
                           onSelectItem={(e) => dispatch(setSelectedRows(e))}
                           onChangePage={(page) => onChangeQuery({page})}
                           isLoading={isLoading}
            />
            { modal?.export &&
                <ExportModal
                title="Apakah anda yakin ingin melakukan export data?"
                description={`Total data list employee : ${selectedRows.length > 0 ? selectedRows.length : totalData}`}
                show={modal?.export}
                onExportHandler={onExport}
                onCancelHandler={() => modalVisibility({export: false})}
            />}
        </>
    )
}
