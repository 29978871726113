import {AdditionalIncentiveSetting} from "components/general-settings/overtime-setting/AdditionalIncentiveSetting"
import {TABLE_ADDITIONAL_INCENTIVE} from "configs/general-settings/configTableOvertimeSetting"
import {ACTION_TYPE} from "constants/constant"
import {useEffect, useState} from "react"
import {useLocation, useSearchParams} from "react-router-dom"
import {constructOptionsSelect, getAllQueryParams, setQueryParams} from "utils/utils"
import {useFetch} from "../../../services/useFetch";
import {API_OVERTIME_SETTING} from "../../../services/apiOvertimeSetting";
import {useValidation} from "../../../hooks/useValidation";
import {formAdditionalIncentiveValidation} from "../../../services/formGeneralSettingValidation";

const INITIAL_STATE = {
    grade_id: null,
    min_incentive: 0,
    avg_incentive: 0,
    max_incentive: 0,
}

export const AdditionalIncentiveSettingContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [form, setForm] = useState(INITIAL_STATE)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [deleteId, setDeleteId] = useState(null)
    const [modal, setModal] = useState({
        form: false,
        delete: false,
        sort: false
    })
    const [optionsGrade, setOptionsGrade] = useState([])
    const {errorForm, clearErrorForm, validateForm} = useValidation({schema: formAdditionalIncentiveValidation})
    const {data: additionalIntensiveList, fetching: getAdditionalIntensiveList, isLoading} = useFetch(API_OVERTIME_SETTING.ADDITIONAL_INCENTIVE_LIST)
    const {fetching: getGradeList} = useFetch(API_OVERTIME_SETTING.GRADE_LIST)
    const {fetching: createAdditionalIncentive, isLoading: isCreating} = useFetch(API_OVERTIME_SETTING.CREATE_ADDITIONAL_INCENTIVE)
    const {fetching: updateAdditionalIncentive, isLoading: isUpdating} = useFetch(API_OVERTIME_SETTING.UPDATE_ADDITIONAL_INCENTIVE)
    const {fetching: deleteAdditionalIncentive, isLoading: isDeleting} = useFetch(API_OVERTIME_SETTING.DELETE_ADDITIONAL_INCENTIVE)

    useEffect(() => {
        fetchAdditionalIncentiveList({...query})
    }, [location.search])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setModal({...modal, ...itemModal})
        setIsShowToast(true)
        setToastMessage(message)
    }

    const fetchAdditionalIncentiveList = (item = query) => {
        getAdditionalIntensiveList(item)
    }

    const onChangeForm = (e) => {
        const {name, value} = e
        setForm({...form, [name]: value})
    }

    const onDelete = () => {
        deleteAdditionalIncentive({}, deleteId)
            .then(() => {
                setShowToast('Additional Incentive Deleted !!', {delete: false})
                if (getAdditionalIntensiveList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchAdditionalIncentiveList()
                }
            })
    }

    const onActionRow = ({item, actionType}) => {
        const combineObj = {...item, grade_id: {label: item?.grade?.name, value: item?.grade?.id}}
        const {grade, ...newDataItem} = combineObj

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setActionType(ACTION_TYPE.CREATE)
                setForm({...INITIAL_STATE})
                setModal({...modal, form: true})
                clearErrorForm()
                break
            case ACTION_TYPE.EDIT:
                setActionType(ACTION_TYPE.EDIT)
                setForm({...newDataItem})
                setModal({...modal, form: true})
                clearErrorForm()
                break
            default:
                setDeleteId(item?.id)
                setModal({...modal, delete: true})
        }
    }

    const onSubmitForm = () => {
        const combineObj = {...form, grade_id: form?.grade_id?.value ?? null}
        const {id, ...payload} = combineObj
        validateForm(payload)
            .then(() => {
                if (actionType === ACTION_TYPE.CREATE) {
                    createAdditionalIncentive(payload)
                        .then(() => {
                            setShowToast('Additional Incentive Created !!', {form: false})
                            fetchAdditionalIncentiveList()
                        })
                } else {
                    updateAdditionalIncentive(payload, id)
                        .then(() => {
                            setShowToast('Additional Incentive Updated !!', {form: false})
                            fetchAdditionalIncentiveList()
                        })
                }
            })
    }

    useEffect(() => {
        if (modal?.form) {
            getGradeList()
                .then(response => {
                    const options = response?.data ? constructOptionsSelect(response?.data, 'name', 'id') : []
                    setOptionsGrade(options)
                })
        }
    }, [modal?.form])

    const props = {
        configTable: TABLE_ADDITIONAL_INCENTIVE,
        dataAdditionalIncentive: additionalIntensiveList?.data,
        query,
        optionsGrade,
        form,
        modal,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        onChangeQuery,
        onActionRow,
        onDelete,
        onSetModal: (e) => setModal({...modal, ...e}),
        onChangeForm,
        onSubmitForm,
        isLoadingTable: isLoading,
        isCreating,
        isUpdating,
        isDeleting
    }

    return (
        <AdditionalIncentiveSetting {...props} />
    )
}
