import icAddSquare from "assets/images/id-add-square.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import FormModal from "components/commons/form-modal"
import Input from "components/commons/input"
import Select from "components/commons/select"
import Table from "components/commons/table"
import {ACTION_TYPE} from "../../../constants/constant";
import ErrorMessage from "../../commons/error-message";
import Toast from "../../commons/toast";
import {
    FilterContainer
} from "../../../containers/general-settings/overtime-setting/government-overtime/FilterContainer";
import ViewList from "../../commons/view-list";

export const GovernmentOvertime = (
    {
        configTable,
        dataGovernmentOvertime,
        query,
        optionsScheduleCategory,
        form,
        modal,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        queryFilter,
        onChangeQuery,
        onActionRow,
        onDelete,
        onSetModal,
        onChangeForm,
        onSubmitForm,
        isLoadingTable,
        isCreating,
        isUpdating,
        isDeleting,
    }) => {

    return (
        <>
            <div className="font-semibold text-md text-neutral-80 p-6">Pengaturan Perhitungan Lembur Pemerintah</div>
            <div className="flex items-center justify-between px-6">
                <div className={"flex gap-3"}>
                    <FilterContainer
                        show={modal.filter}
                        onShow={() => onSetModal({filter: true})}
                        onClose={() => onSetModal({filter: false})}
                        optionsScheduleCategory={optionsScheduleCategory}
                        queryFilter={queryFilter}
                        onSubmit={onChangeQuery}
                    />
                    <ViewList
                        show={modal?.sort}
                        onShow={() => onSetModal({sort: true})}
                        onClose={() => onSetModal({sort: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                </div>
                <Button title="Create Perhitungan Lembur Pemerintah" icon={icAddSquare}
                        onClick={() => onActionRow({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                classNameHeader="first:!pl-6 last:!pr-6"
                classNameRow="first:!pl-6 last:!pr-6"
                name={configTable.name}
                data={dataGovernmentOvertime?.result}
                columns={configTable.column}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataGovernmentOvertime?.total_page}
                totalResult={dataGovernmentOvertime?.total_result}
                onChangePage={(page) => onChangeQuery({page})}
                onClickItem={onActionRow}
                loading={isLoadingTable}
            />
            <DeleteModal
                text="Apakah anda yakin ingin menghapus data lembur pemerintah?"
                show={modal.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
            />
            {
                modal?.form &&
                <FormModal
                    title={`${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Perhitungan Lembur Pemerintah`}
                    show={modal?.form}
                    width="max-w-[640px]"
                    onHide={() => onSetModal({form: false})}
                    isLoading={isCreating || isUpdating}
                    onSubmitForm={onSubmitForm}
                >
                    <div className="flex flex-col space-y-5 px-8 py-6">
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Tipe Jam
                                Kerja
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="schedule_category_id"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih tipe kerja"
                                    options={optionsScheduleCategory}
                                    value={form?.schedule_category_id}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2"
                                              message={errorForm?.schedule_category_id}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Durasi
                                Lembur
                            </div>
                            <div className="flex-1">
                                <Input
                                    name="duration"
                                    className="flex-1"
                                    addonAfter="Jam"
                                    type="float"
                                    value={form?.duration}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.duration}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Benefit
                                Hari Kerja
                            </div>
                            <div className="flex-1">
                                <Input
                                    name="benefit_on_working_day"
                                    className="flex-1"
                                    addonAfter="Kali"
                                    type="float"
                                    value={form?.benefit_on_working_day}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2"
                                              message={errorForm?.benefit_on_working_day}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Benefit
                                Hari Libur
                            </div>
                            <div className="flex-1">
                                <Input
                                    name="benefit_on_holiday"
                                    className="flex-1"
                                    addonAfter="Kali"
                                    type="float"
                                    value={form?.benefit_on_holiday}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2"
                                              message={errorForm?.benefit_on_holiday}/>
                            </div>
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
