import {useEffect, useState} from "react";
import {useValidation} from "../../hooks/useValidation";
import {AppVersion} from "../../components/app-version/AppVersion";
import {useFetch} from "../../services/useFetch";
import {API_APP_VERSION} from "../../services/apiAppVersion";

const INITIAL_STATE = {
    android_version: null,
    ios_version: null,
    is_update_android: 0,
    is_update_ios: 0,
}

export const AppVersionContainer = () => {
    const [form, setForm] = useState(INITIAL_STATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const {errorForm, validateForm} = useValidation({
        schema: {
            android_version: {
                required: "Android version tidak boleh kosong"
            },
            ios_version: {
                required: "iOS version tidak boleh kosong"
            }
        }
    })
    const {fetching: detailVersion} = useFetch(API_APP_VERSION.DETAIL_VERSION)
    const {fetching: updateVersion, isLoading: isUpdating} = useFetch(API_APP_VERSION.UPDATE_VERSION)

    const clearToast = () => {
        setIsShowToast(false)
    }

    const onChangeForm = (e) => {
        const {name, value, checked} = e
        if (['is_update_android', 'is_update_ios'].includes(name)) {
            setForm({...form, [name]: +checked})
        } else {    
            setForm({...form, [name]: value})
        }
    }

    const onSubmit = () => {
        validateForm(form)
            .then(() => {
                updateVersion(form)
                    .then(() => setIsShowToast(true))
            })
    }

    useEffect(() => {
        detailVersion()
            .then(response => setForm({...form, ...response?.data}))
    }, [])

    const props = {
        form,
        isShowToast,
        clearToast,
        onChangeForm,
        onSubmit,
        isUpdating,
        errorForm
    }

    return (
        <AppVersion {...props} />
    )
}
