import {METHOD} from "./method";

export const API_OVERTIME_SETTING = {
    OVERTIME_LOCATION_LIST: {
        api: '/v1/hr-adm/general-setting/overtime-location',
        method: METHOD.GET
    },
    CREATE_OVERTIME_LOCATION: {
        api: '/v1/hr-adm/general-setting/overtime-location',
        method: METHOD.POST
    },
    UPDATE_OVERTIME_LOCATION: {
        api: '/v1/hr-adm/general-setting/overtime-location',
        method: METHOD.PUT
    },
    DELETE_OVERTIME_LOCATION: {
        api: '/v1/hr-adm/general-setting/overtime-location',
        method: METHOD.DELETE
    },
    BENEFIT_REPRESENTATIVE_LIST: {
        api: '/v1/hr-adm/general-setting/representative-allowance/list',
        method: METHOD.POST
    },
    SCHEDULE_CATEGORY_LIST: {
        api: '/v1/hr-adm/general-setting/schedule-category',
        method: METHOD.GET
    },
    GRADE_LIST: {
        api: '/v1/hr-adm/general-setting/grade',
        method: METHOD.GET
    },
    CREATE_BENEFIT_REPRESENTATIVE: {
        api: '/v1/hr-adm/general-setting/representative-allowance',
        method: METHOD.POST
    },
    UPDATE_BENEFIT_REPRESENTATIVE: {
        api: '/v1/hr-adm/general-setting/representative-allowance',
        method: METHOD.PUT
    },
    DELETE_BENEFIT_REPRESENTATIVE: {
        api: '/v1/hr-adm/general-setting/representative-allowance',
        method: METHOD.DELETE
    },
    ADDITIONAL_INCENTIVE_LIST: {
        api: '/v1/hr-adm/general-setting/additional-incentive',
        method: METHOD.GET
    },
    CREATE_ADDITIONAL_INCENTIVE: {
        api: '/v1/hr-adm/general-setting/additional-incentive',
        method: METHOD.POST
    },
    UPDATE_ADDITIONAL_INCENTIVE: {
        api: '/v1/hr-adm/general-setting/additional-incentive',
        method: METHOD.PUT
    },
    DELETE_ADDITIONAL_INCENTIVE: {
        api: '/v1/hr-adm/general-setting/additional-incentive',
        method: METHOD.DELETE
    },
    GOVERNMENT_OVERTIME_LIST: {
        api: '/v1/hr-adm/general-setting/government-overtime/list',
        method: METHOD.POST
    },
    CREATE_GOVERNMENT_OVERTIME: {
        api: '/v1/hr-adm/general-setting/government-overtime',
        method: METHOD.POST
    },
    UPDATE_GOVERNMENT_OVERTIME: {
        api: '/v1/hr-adm/general-setting/government-overtime',
        method: METHOD.PUT
    },
    DELETE_GOVERNMENT_OVERTIME: {
        api: '/v1/hr-adm/general-setting/government-overtime',
        method: METHOD.DELETE
    },
    BENEFIT_LIST: {
        api: '/v1/hr-adm/general-setting/benefit',
        method: METHOD.GET
    },
    OVERTIME_SETTING_LIST: {
        api: '/v1/hr-adm/general-setting/overtime-setting/list',
        method: METHOD.POST
    },
    CREATE_OVERTIME_SETTING_LIST: {
        api: '/v1/hr-adm/general-setting/overtime-setting',
        method: METHOD.POST
    },
    UPDATE_OVERTIME_SETTING_LIST: {
        api: '/v1/hr-adm/general-setting/overtime-setting',
        method: METHOD.PUT
    },
    DELETE_OVERTIME_SETTING_LIST: {
        api: '/v1/hr-adm/general-setting/overtime-setting',
        method: METHOD.DELETE
    }
}
