import { GeneralOvertimeSetting } from "components/general-settings/overtime-setting/GeneralOvertimeSetting"
import {useEffect, useState} from "react"
import { AdditionalIncentiveSettingContainer } from "./AdditionalIncentiveSettingContainer"
import { BenefitsRepresentativeContainer } from "./benefit-representative/BenefitsRepresentativeContainer"
import { GovernmentOvertimeContainer } from "./government-overtime/GovernmentOvertimeContainer"
import { OvertimeLocationContainer } from "./OvertimeLocationContainer"
import { OvertimeSettingContainer } from "./overtime-setting/OvertimeSettingContainer"
import {useNavigate} from "react-router-dom";

const overtimeSettingList = [
    {
        label: 'Lokasi Lembur',
        key: 'overtime_location',
        component: <OvertimeLocationContainer />
    },
    {
        label: 'Tunjangan Representative',
        key: 'benefits_representative',
        component: <BenefitsRepresentativeContainer />
    },
    {
        label: 'Additional Incentive',
        key: 'additional_incentive',
        component: <AdditionalIncentiveSettingContainer />
    },
    {
        label: 'Perhitungan Lembur Pemerintah',
        key: 'government_overtime',
        component: <GovernmentOvertimeContainer />
    },
    {
        label: 'Pengaturan Lembur',
        key: 'overtime_setting',
        component: <OvertimeSettingContainer />
    },
]

export const GeneralOvertimeSettingContainer = () => {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState({})

    const onChangeTab = (tab) => {
        navigate('/general-settings/overtime-setting')
        setSelectedTab(tab)
    }

    useEffect(() => {
        navigate('/general-settings/overtime-setting')
        setSelectedTab(overtimeSettingList[0])
    }, [])

    const props = {
        overtimeSettingList,
        selectedTab,
        onChangeTab
    }

    return (
        <GeneralOvertimeSetting {...props} />
    )
}
