import {BenefitsRepresentative} from "components/general-settings/overtime-setting/BenefitsRepresentative"
import {TABLE_BENEFITS_REPRESENTATIVE} from "configs/general-settings/configTableOvertimeSetting"
import {ACTION_TYPE} from "constants/constant"
import {useEffect, useMemo, useState} from "react"
import {useLocation, useSearchParams} from "react-router-dom"
import {constructOptionsSelect, getAllQueryParams, setQueryParams} from "utils/utils"
import {useFetch} from "../../../../services/useFetch";
import {API_OVERTIME_SETTING} from "../../../../services/apiOvertimeSetting";
import {useValidation} from "../../../../hooks/useValidation";
import {formBenefitsRepresentativeValidation} from "../../../../services/formGeneralSettingValidation";

const INITIAL_STATE = {
    schedule_category_id: null,
    grade_id: null,
    non_jakarta_based: 0,
    jakarta_based: 0
}

const FILTER_PARAMS = ['schedule_category_ids', 'grade_ids']

export const BenefitsRepresentativeContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [form, setForm] = useState(INITIAL_STATE)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [deleteId, setDeleteId] = useState(null)
    const [queryFilter, setQueryFilter] = useState()
    const [bodyRequest, setBodyRequest] = useState(defaultQuery)
    const [modal, setModal] = useState({
        filter: false,
        sort: false,
        form: false,
        delete: false,
    })
    const {errorForm, clearErrorForm, validateForm} = useValidation({schema: formBenefitsRepresentativeValidation})
    const {data: benefitRepresentativeList, fetching: getBenefitRepresentativeList, isLoading} = useFetch(API_OVERTIME_SETTING.BENEFIT_REPRESENTATIVE_LIST)
    const {data: dataScheduleCategory} = useFetch(API_OVERTIME_SETTING.SCHEDULE_CATEGORY_LIST, {}, {isFetching: true})
    const {data: dataGrade} = useFetch(API_OVERTIME_SETTING.GRADE_LIST, {}, {isFetching: true})
    const {fetching: createBenefitRepresentative, isLoading: isCreating} = useFetch(API_OVERTIME_SETTING.CREATE_BENEFIT_REPRESENTATIVE)
    const {fetching: updateBenefitRepresentative, isLoading: isUpdating} = useFetch(API_OVERTIME_SETTING.UPDATE_BENEFIT_REPRESENTATIVE)
    const {fetching: deleteBenefitRepresentative, isLoading: isDeleting} = useFetch(API_OVERTIME_SETTING.DELETE_BENEFIT_REPRESENTATIVE)

    useEffect(() => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = item.split(',')
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })

        const body = {
            ...bodyRequest,
            schedule_category_ids: newQuery.schedule_category_ids || [],
            grade_ids: newQuery.grade_ids || [],
            limit: newQuery.limit,
            page: newQuery.page,
        }

        setQueryFilter(newQueryFilter)
        setBodyRequest(body)
        fetchBenefitRepresentativeList(body)
    }, [location.search])

    const optionsScheduleCategory = useMemo(() => {
        if (dataScheduleCategory?.data?.length === 0) return []
        return constructOptionsSelect(dataScheduleCategory?.data, 'name', 'id')
    }, [dataScheduleCategory?.data])

    const optionsGrades = useMemo(() => {
        if (dataGrade?.data?.length === 0) return []
        return constructOptionsSelect(dataGrade?.data, 'name', 'id')
    }, [dataGrade?.data])

    const options = {
        schedule_category: optionsScheduleCategory,
        grades: optionsGrades
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setModal({...modal, ...itemModal})
        setIsShowToast(true)
        setToastMessage(message)
    }

    const fetchBenefitRepresentativeList = (item = query) => {
        getBenefitRepresentativeList(item)
    }

    const onChangeForm = (e) => {
        const {name, value} = e
        setForm({...form, [name]: value})
    }

    const onDelete = () => {
        deleteBenefitRepresentative({}, deleteId)
            .then(() => {
                setShowToast('Representative Allowance Deleted !!', {delete: false})
                if (getBenefitRepresentativeList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchBenefitRepresentativeList(bodyRequest)
                }
            })
    }

    const constructOptionSelected = (item) => {
        return {label: item?.name, value: item?.id}
    }

    const onActionRow = ({item, actionType}) => {
        const combineObj = {
            ...item,
            schedule_category_id: constructOptionSelected(item?.schedule_category),
            grade_id: constructOptionSelected(item?.grade)
        }
        const {grade, schedule_category, ...newDataItem} = combineObj

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setActionType(ACTION_TYPE.CREATE)
                setForm({...INITIAL_STATE})
                setModal({...modal, form: true})
                clearErrorForm()
                break
            case ACTION_TYPE.EDIT:
                setActionType(ACTION_TYPE.EDIT)
                setForm({...newDataItem})
                setModal({...modal, form: true})
                clearErrorForm()
                break
            default:
                setDeleteId(item?.id)
                setModal({ ...modal, delete: true })
        }
    }

    const constructBenefitRepresentativeDetail = () => {
        const formDetailCopy = {...form}
        Object.keys(formDetailCopy)?.forEach(key => {
            if (key === 'schedule_category_id' || key === 'grade_id') {
                formDetailCopy[key] = formDetailCopy[key]?.value ?? null
            }
        })

        return formDetailCopy
    }

    const onSubmitForm = () => {
        const {id, ...payload} = constructBenefitRepresentativeDetail()
        validateForm(payload)
            .then(() => {
                if (actionType === ACTION_TYPE.CREATE) {
                    createBenefitRepresentative(payload)
                        .then(() => {
                            setShowToast('Representative Allowance Created !!', {form: false})
                            fetchBenefitRepresentativeList(bodyRequest)
                        })
                } else {
                    updateBenefitRepresentative(payload, id)
                        .then(() => {
                            setShowToast('Representative Allowance Updated !!', {form: false})
                            fetchBenefitRepresentativeList(bodyRequest)
                        })
                }
            })
    }

    const props = {
        configTable: TABLE_BENEFITS_REPRESENTATIVE,
        dataBenefitRepresentative: benefitRepresentativeList?.data,
        query,
        options,
        form,
        modal,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        queryFilter,
        onChangeQuery,
        onActionRow,
        onDelete,
        onSetModal: (e) => setModal({...modal, ...e}),
        onChangeForm,
        onSubmitForm,
        isLoadingTable: isLoading,
        isCreating,
        isUpdating,
        isDeleting
    }

    return (
        <BenefitsRepresentative {...props} />
    )
}
