import Input from "../commons/input";
import ErrorMessage from "../commons/error-message";
import Button from "../commons/button";
import Toast from "../commons/toast";
import Checkbox from "components/commons/checkbox";

export const AppVersion = (
    {
        form,
        isShowToast,
        clearToast,
        onChangeForm,
        onSubmit,
        isUpdating,
        errorForm
    }) => {

    return (
        <div className="p-9">
            <div className="w-1/3 flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                    <div className="space-y-2">
                        <div className={"font-medium required"}>iOS</div>
                        <div className="text-neutral-70">App Versioning for iOS</div>
                        <div className={"font-medium text-neutral-70"}>Recommended Version</div>
                    </div>
                    <div>
                        <Input
                            name="ios_version"
                            placeholder="Version"
                            value={form?.ios_version}
                            onChange={onChangeForm}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.ios_version}/>
                    </div>
                    <Checkbox
                        name="is_update_ios"
                        checked={form?.is_update_ios}
                        label={'Show version alert'}
                        onChange={onChangeForm}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <div className="space-y-2">
                        <div className={"font-medium required"}>Android</div>
                        <div className="text-neutral-70">App Versioning for Android</div>
                        <div className={"font-medium text-neutral-70"}>Recommended Version</div>
                    </div>
                    <div>
                        <Input
                            name="android_version"
                            placeholder="Version"
                            value={form?.android_version}
                            onChange={onChangeForm}
                        />
                        <ErrorMessage className="mt-2" message={errorForm?.android_version}/>
                    </div>
                    <Checkbox
                        name="is_update_android"
                        checked={form?.is_update_android}
                        label={'Show version alert'}
                        onChange={onChangeForm}
                    />
                </div>
                <Button className="ml-auto w-32" title="Simpan" loading={isUpdating} onClick={onSubmit}/>
            </div>
            <Toast
                show={isShowToast}
                message="App Version Updated !!"
                onClose={() => clearToast()}
            />
        </div>
    )
}
