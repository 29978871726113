import Table from "../../commons/table";
import {TABLE_ANALYTICS_ATTENDANCE_LIST} from "../../../configs/attendance/configTableAnalyticsList";

export const AnalyticsList = ({ analyticsAttendanceList, query, onSelectItem, onChangePage, isLoading }) => {
    return (
        <Table
            className="my-3"
            name={TABLE_ANALYTICS_ATTENDANCE_LIST.name}
            data={analyticsAttendanceList?.result}
            columns={TABLE_ANALYTICS_ATTENDANCE_LIST.column}
            size={query?.limit}
            totalPage={analyticsAttendanceList?.total_page}
            currentPage={query?.page}
            totalResult={analyticsAttendanceList?.total_data}
            onChangePage={onChangePage}
            onSelectItem={onSelectItem}
            loading={isLoading}
        />
    )
}
