import {FormContract} from "../../../components/management-contract/form-contract/FormContract";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../../services/useFetch";
import {API_CONTRACT} from "../../../services/apiContract";
import {useSearchParams} from "react-router-dom";
import {setContractDetail, setOptions} from "../../../reducers/contractReducer";
import {constructOptionsSelect, deepClone} from "../../../utils/utils";
import {AddNotesModalContainer} from "../contract-employee/add-notes-modal/AddNotesModalContainer";
import {useEffect, useState} from "react";
import {STATUS_TYPE} from "../../../constants/constant";
import {useValidation} from "../../../hooks/useValidation";
import {addendumContractValidation, contractValidation} from "../../../services/addContractValidation";
import {Alert} from "../../../components/commons/alert";

export const FormContractContainer = (props) => {
    const {onSubmitForm, activity, form} = props
    const [searchParams] = useSearchParams();
    const employeeId = searchParams.get('employeeId')
    const dispatch = useDispatch()
    const [formUpdated, setFormUpdated] = useState()
    const {options, contractDetail} = useSelector(state => state.contractReducer)
    const {data: employeeDetail, fetching: getEmployeeDetailApi} = useFetch({
        ...API_CONTRACT.EMPLOYEE_DETAIL,
        initPathVariable: employeeId
    })
    const {fetching: getJobTitleByCompanyApi} = useFetch(API_CONTRACT.LIST_JOB_TITLE)
    const {fetching: getGradeByJobTitleApi} = useFetch(API_CONTRACT.LIST_GRADE)
    const {fetching: getAllowanceByGradeApi} = useFetch(API_CONTRACT.ALLOWANCE)
    const [addNotesModal, setAddNotesModal] = useState(false)
    const {isFormValid, validateForm, errorForm} = useValidation({schema: contractValidation, form: contractDetail})
    const {validateForm: validateFormAddendum, errorForm: errorFormAddendum} = useValidation({
        schema: addendumContractValidation,
        form: contractDetail
    })

    useEffect(() => {
        setFormUpdated(form)
    }, [form])

    useEffect(() => {
        getEmployeeDetailApi()
            .then(res => {
                if (!res?.data.is_bank_verified && activity === 'create') {
                    Alert({
                        icon: 'warning',
                        title: 'Rekening Tidak Valid',
                        message: 'Mohon verifikasi rekening!!',
                        buttonConfirmText: 'OK',
                        autoClose: false,
                    }).then(() => {
                        window.location.href = `/employee/prospective-employee/user-detail?userId=${employeeId}`
                    })
                }
            })
    }, []);

    const onSave = (value) => {
        let newContractDetail = {
            [value.name]: value.value
        }

        switch (value.name) {
            case 'company':
                newContractDetail.job_title = null
                newContractDetail.grade = null
                if((formUpdated[formUpdated?.length - 1].form?.length !== 2) && (!activity.includes('interim') && !contractDetail?.is_interim)) emptyingAllowance()
                getJobTitle(value.value.value)
                break;
            case 'job_title':
                newContractDetail.grade = null
                newContractDetail.job_description = !!value.value.description ? value.value.description : "<p></p>"
                if((formUpdated[formUpdated?.length - 1].form?.length !== 2) && (!activity.includes('interim') && !contractDetail?.is_interim)) emptyingAllowance()
                getGrade(value.value.value)
                break;
            case 'type':
                if (value.value.value === 'PKWTT') newContractDetail.end_date = null
                let isAddendum = !!contractDetail.addendum_number
                form[isAddendum ? 2 : 1].form[5].hide = (value.value.value === 'PKWTT')
                break
            case 'grade':
                if(!activity.includes('interim') && !contractDetail?.is_interim) getAllowance(value.value.value)
                break;
        }
        dispatch(setContractDetail(newContractDetail))
    }

    const getJobTitle = (company_id) => {
        getJobTitleByCompanyApi({company_id}, employeeId)
            .then(result => {
                dispatch(setOptions({job_title: constructOptionsSelect(result.data, 'title', 'id', 'description')}))
            })
    }

    const getGrade = (job_title_id) => {
        getGradeByJobTitleApi({job_title_id})
            .then(result => {
                dispatch(setOptions({grade: constructOptionsSelect(result.data, 'name', 'id')}))
            })
    }

    const getAllowance = (grade_id) => {
        getAllowanceByGradeApi({gradeId:grade_id, companyId: contractDetail?.company?.value})
            .then(result => {
                constructAllowance(result.data.allowances)
                dispatch(setContractDetail(result.data))
            })
    }

    const constructAllowance = (allowances) => {
        let index = 2
        let copyForm = deepClone(form)
        let copyFormSalary = deepClone(form[form?.length - 1].form)
        if(copyFormSalary?.length === 2){
            //adding allowance form for new contract or renew contract
            copyForm[form?.length - 1].form = copyFormSalary.concat(allowances)
        } else {
            //update value allowance on form
            allowances.forEach((item) => {
                copyFormSalary[index].value = item.value
                index = index+1
            })
            copyForm[form?.length-1].form = copyFormSalary
        }
        setFormUpdated(copyForm)
    }

    const emptyingAllowance = () => {
        let index = 2
        let copyForm = deepClone(formUpdated)
        let copyFormSalary = deepClone(formUpdated[formUpdated?.length - 1].form)
        if(copyFormSalary?.length > 2){
            copyFormSalary.forEach((item, idx) => {
                if(idx > 1){
                    copyFormSalary[index].value = 0
                    index = index+1
                }
            })
            copyForm[form?.length-1].form = copyFormSalary
            setFormUpdated(copyForm)
        }
    }

    const onSubmit = () => {
        let validationForm = ((activity === 'addendum') || contractDetail.addendum_number) ? validateFormAddendum : validateForm
        const formValidate = {...contractDetail}
        if (contractDetail.type.value === 'PKWTT') delete formValidate.end_date

        validationForm(formValidate)
            .then(() => {
                if (activity === 'edit' && contractDetail.status !== STATUS_TYPE.DRAFT) {
                    setAddNotesModal(true)
                } else {
                    onSubmitForm()
                }
            })
    }

    const checkValidationForm = () => {
        if (activity === 'create') {
            const checkForm = {...contractDetail}
            if (contractDetail?.type?.value === 'PKWTT') delete checkForm.end_date
            return isFormValid(checkForm)
        }
        return true
    }

    let errorFormValidation = activity === 'addendum' ? errorFormAddendum : errorForm

    let propsForm = {
        ...props,
        form: formUpdated,
        options,
        employeeDetail: employeeDetail?.data,
        contractDetail,
        onSave,
        isFormValid: checkValidationForm(),
        onSubmitForm: onSubmit,
        errorForm: {...props.errorForm, ...errorFormValidation}
    }

    if (employeeDetail?.data) {
        return (
            <>
                <FormContract {...propsForm} />
                <AddNotesModalContainer
                    show={addNotesModal}
                    onHide={() => setAddNotesModal(false)}
                    onSubmitModal={(notes) => onSubmitForm(notes)}
                    employeeDetail={employeeDetail?.data}
                    contractDetail={contractDetail}
                />
            </>

        )
    }
}
