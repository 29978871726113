import clsx from "clsx"
import {useEffect, useState} from "react"
import Checkbox from "../checkbox"
import Pagination from '../pagination'
import "./table.scss"
import icSortInactive from "./ic-sort-inactive.svg"
import icSortDown from "./ic-sort-down.svg"
import icSortUp from "./ic-sort-up.svg"
import Spinner from "../spinner";
import Tooltip from "../tooltip";

const Table = ({
                   data,
                   columns,
                   className,
                   classNameHeader,
                   classNameRow,
                   onSelectItem,
                   name,
                   size,
                   currentPage = 1,
                   totalPage,
                   totalResult,
                   onChangePage,
                   onClickItem,
                   onClickSort,
                   style,
                   onClickRow,
                   loading = false
               }) => {

    const [sCurrentPage, setCurrentPage] = useState(currentPage)
    const [store, setStore] = useState({})
    const [sortable, setSortable] = useState([])
    const isIndeterminate = store[sCurrentPage]?.selected_item?.length > 0 && store[sCurrentPage]?.selected_item?.length !== store[sCurrentPage]?.data_table?.length

    useEffect(() => {
        if (data) {
            const newData = {}
            newData[sCurrentPage] = {
                data_table: [...data],
                is_check_all: false,
                selected_item: []
            }
            setStore({...newData})
        }
    }, [data])

    useEffect(() => {
        if (currentPage) setCurrentPage(currentPage)
    }, [currentPage])

    useEffect(() => {
        setSortable(getSortableColumns())
    }, [])

    useEffect(() => {
        if (!store[sCurrentPage]) return
        if (!onSelectItem) return
        let selectedItem = []
        Object.entries(store).forEach(([key, item]) => {
            if (item?.selected_item) selectedItem = [...selectedItem, ...item.selected_item]
        });
        onSelectItem(selectedItem)
    }, [store])

    const getSortableColumns = () => {
        const sortableColumns = columns?.reduce?.((data, currentItem) => {
            if (currentItem?.subColumn) {
                const newData = currentItem?.subColumn?.filter(i => i?.sortable)
                data = [...data, ...newData]
            } else {
                currentItem?.sortable && data.push(currentItem)
            }
            return data
        }, [])

        return sortableColumns?.map(item => {
            return {key: item.key, isSorting: false, sortedType: null}
        })
    }

    const handleChangePage = (page) => {
        setCurrentPage(page)
        if (onChangePage) onChangePage(page)
    }

    const handleCheckAll = (isCheckAll) => {
        if (store[sCurrentPage]?.is_check_all === undefined) return
        store[sCurrentPage].is_check_all = isIndeterminate || isCheckAll
        if (isIndeterminate || isCheckAll) {
            const allSelectedItem = store[sCurrentPage].data_table
            store[sCurrentPage].selected_item = [...allSelectedItem]
        } else {
            store[sCurrentPage].selected_item = []
        }
        setStore({...store})
    }

    const handleOnChecked = (checkedItem) => {
        const valueItem = JSON.parse(checkedItem.value)
        if (checkedItem.checked) {
            store[sCurrentPage].selected_item.push(valueItem)
        } else {
            const filterChecked = store[sCurrentPage].selected_item.filter((item) => item.id !== valueItem.id)
            store[sCurrentPage].selected_item = [...filterChecked]
        }

        const currentDataLength = store[sCurrentPage]?.data_table?.length
        if (store[sCurrentPage].selected_item.length === size || store[sCurrentPage].selected_item.length === currentDataLength) {
            store[sCurrentPage].is_check_all = true
        } else {
            store[sCurrentPage].is_check_all = false
        }
        setStore({...store})
    }

    const constructSortableList = (item, sortType) => {
        return sortable?.map?.(i => {
            return (i.key === item?.key) ?
                {...i, isSorting: true, sortedType: sortType} :
                {...i, isSorting: false, sortedType: null}
        })
    }

    const handleOnSort = (item) => {
        const sortType = item?.sortedType === 'asc' ? 'desc' : 'asc'
        setSortable(constructSortableList(item, sortType))
        if (onClickSort) onClickSort({order_by: item?.key, order_type: sortType})
    }

    const getIconSort = (item) => {
        if (!item?.isSorting) {
            return icSortInactive
        } else if (item?.sortedType === 'asc') {
            return icSortDown
        } else {
            return icSortUp
        }
    }

    const renderIconSort = (keyName) => {
        const sortableItem = sortable?.find(item => item?.key === keyName)

        return (
            <img src={getIconSort(sortableItem)} alt="ic-sort" className="icon-sort"
                 onClick={() => handleOnSort(sortableItem)}/>
        )
    }

    const renderItemBody = (index, item, column, subColumn) => {
        const itemColumn = subColumn ?? column

        return (
            <td
                className={clsx(classNameRow, itemColumn?.className)}
                key={itemColumn?.key} colSpan={itemColumn?.colSpan ?? 1}
            >
                {itemColumn?.render
                    ? itemColumn?.render({
                        value: item[itemColumn?.key] ?? item[column?.key]?.[itemColumn?.key],
                        index,
                        sCurrentPage,
                        size,
                        item,
                        onClickItem
                    })
                    : (item[itemColumn?.key] ?? item[column?.key]?.[itemColumn?.key]) ?? "-"
                }
            </td>
        )
    }

    return (
        <div className={clsx("avo__table", className)} style={style}>
            {
                !!totalResult &&
                <div className="table-header">
                    <span className="table-name"><span className="total-result">{totalResult}</span> {`${name}`}</span>
                </div>
            }
            <div className="table-container">
                <table className="table">
                    <thead>
                    <tr>
                        {!!onSelectItem &&
                            <th className={clsx(classNameHeader, "select-item")} colSpan={1} rowSpan={2} width={30}>
                                <Checkbox
                                    name={name}
                                    indeterminate={isIndeterminate}
                                    checked={isIndeterminate || store[sCurrentPage]?.is_check_all || false}
                                    onChange={(e) => handleCheckAll(e.checked)}
                                />
                            </th>}
                        {
                            columns.map((column, index) => (
                                <th
                                    key={index}
                                    className={clsx(classNameHeader, column?.subColumn?.length && "head-column", column?.classNameHeader)}
                                    colSpan={column?.subColumn?.length ?? 1}
                                    rowSpan={column?.subColumn ? 1 : 2}
                                    width={column?.width}
                                >
                                    {
                                        column?.isShowTooltip ?
                                            <Tooltip
                                                content={<div className={column?.classNameTooltip}>{column?.name}</div>}
                                                text={column?.name}
                                            />
                                            :
                                            column?.name
                                    }
                                    {
                                        (!column?.subColumn?.length && column?.sortable) && renderIconSort(column?.key)
                                    }
                                    <div className="head-border-bottom"/>
                                </th>
                            ))
                        }
                    </tr>
                    <tr>
                        {
                            columns.map(column =>
                                column?.subColumn?.map((subColumn, index) =>
                                    <td
                                        key={index}
                                        className={clsx(classNameHeader, subColumn?.classNameHeader)}
                                        colSpan={subColumn?.colSpan ?? 1}
                                        width={subColumn?.width}
                                    >
                                        {
                                            subColumn?.isShowTooltip ?
                                                <Tooltip
                                                    content={<div
                                                        className={subColumn?.classNameTooltip}>{subColumn?.name}</div>}
                                                    text={subColumn?.name}
                                                />
                                                :
                                                subColumn?.name
                                        }
                                        {
                                            subColumn?.sortable && renderIconSort(subColumn?.key)
                                        }
                                    </td>
                                ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading ?
                            <tr>
                                <td colSpan="100%">
                                    <div className="table-spinner">
                                        <Spinner className="spinner-size"/>
                                    </div>
                                </td>
                            </tr>
                            :
                            <>
                                {store?.[sCurrentPage]?.data_table?.map((item, index) => (
                                    <tr key={index} onClick={!!onClickRow ? () => onClickRow(item) : null}
                                        className={`${!!onClickRow ? 'cursor-pointer' : ''}`}>
                                        {!!onSelectItem && <td colSpan={1} width={10} className="select-item row">
                                            <div>
                                                <Checkbox
                                                    name={`${name}-${item[columns[0].key]}-${index}`}
                                                    value={JSON.stringify(item)}
                                                    checked={store[sCurrentPage]?.selected_item?.some(selectedItem => selectedItem.id === item.id)}
                                                    onChange={(e) => handleOnChecked(e)}
                                                />
                                            </div>
                                        </td>}
                                        {
                                            columns.map(column =>
                                                column?.subColumn ?
                                                    column?.subColumn?.map(subColumn =>
                                                        renderItemBody(index, item, column, subColumn)
                                                    ) :
                                                    renderItemBody(index, item, column)
                                            )
                                        }
                                    </tr>)
                                )}
                            </>
                    }
                    </tbody>
                </table>
                {(data?.length === 0 || !data) && <div>No data available in table</div>}
            </div>
            <div className="table-border-bottom"/>
            {
                (!!totalResult || !!onChangePage) &&
                <div className="table-footer">
                    {
                        !!totalResult &&
                        <span className="table-name"><span
                            className="total-result">{totalResult}</span> {`${name}`}</span>
                    }
                    {onChangePage && (data?.length > 0) &&
                        <Pagination
                            currentPage={data?.length > 0 ? Number(sCurrentPage) : 0}
                            totalPage={totalPage}
                            onChange={(e) => handleChangePage(e)}
                        />
                    }
                </div>
            }
        </div>
    )
}

export default Table
