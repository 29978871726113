import { METHOD } from "./method";

export const API_ATTENDANCE = {
    ATTENDANCE_LIST: {
        api: '/v1/hr-adm/attendance/list',
        method: METHOD.POST
    },
    SCHEDULE_CATEGORY_LIST: {
        api: '/v1/hr-adm/attendance/schedule-category',
        method: METHOD.GET
    },
    LIST_JOB_UNIT: {
        api: '/v1/hr-adm/attendance/job-unit',
        method: METHOD.POST
    },
    EXPORT_ATTENDANCE_LIST: {
        api: '/v1/hr-adm/attendance/export',
        method: METHOD.POST
    },
    ATTENDANCE_DETAIL: {
        api: '/v1/hr-adm/attendance',
        method: METHOD.GET
    },
    ANALYTICS_LIST: {
        api: '/v1/hr-adm/analytic/attendance',
        method: METHOD.POST
    },
    ANALYTICS_EXPORT: {
        api: '/v1/hr-adm/analytic/attendance/export',
        method: METHOD.POST
    },
}
