export const TABLE_ANALYTICS_ATTENDANCE_LIST = {
    name: 'Employee',
    column: [
        {
            name: "Nama Karyawan",
            key: "full_name",
            className: "font-semibold"
        }, {
            name: "NIP",
            key: "nip",
        }, {
            name: "Job Title",
            key: "job_title",
            className: "font-semibold"
        }, {
            name: "Jumlah Absent",
            key: "absent_count",
        }, {
            name: "Jumlah Presensi",
            key: "presence_count",
        }, {
            name: "Jumlah Cuti Sakit",
            key: "sick_leave_count",
        }, {
            name: "Jumlah Unpaid Leave",
            key: "unpaid_leave_count",
        },
    ]
}
