import clsx from "clsx";
import './search.scss';
import icSearch from "./ic-search.svg";
import icXGrey from "./ic-x-grey.svg";
import {useEffect, useRef, useState} from "react";
import {useValidation} from "../../../hooks/useValidation";
import Tooltip from "../tooltip";

const VALIDATION = {
    keyword: {
        minimum: {
            value: 2,
            message: "Keyword tidak valid (Min. 2 karakter)"
        },
        maximum: {
            value: 50,
            message: "Keyword yang anda masukkan\nterlalu panjang"
        },
        format: {
            pattern: /^[A-Za-z0-9@.\s()\-&]*$/,
            message: "Keyword tidak valid. keyword terdiri\ndari alphabet, angka, dan spesial\nkarakter seperti @."
        },
    },
}

const Search = ({className, classNameInput, placeholder = "Search...", value = '', onSearch}) => {
    const inputRef = useRef()
    const [isFocus, setIsFocus] = useState(false)
    const [sValue, setValue] = useState('')
    const {errorForm, clearErrorForm, validateForm} = useValidation({schema: VALIDATION})

    useEffect(() => {
        setValue(value)
    }, [value])

    const handleOnChange = (e) => {
        clearErrorForm()
        setValue(e.target?.value)
    }

    const handleOnKeyDown = (e) => {
        if (onSearch && e.key === 'Enter') {
            validateForm({keyword: sValue})
                .then(() => {
                    onSearch(sValue)
                })
        }
    }

    const handleOnClick = () => {
        setValue('')
        inputRef.current.focus()
        clearErrorForm()
        if (onSearch) onSearch('')
    }

    return (
        <Tooltip
            position="bottom"
            content={
                <div className={clsx("avo__search", isFocus && 'focus', sValue?.length > 0 && 'show-close-icon', className)}>
                    <img src={icSearch} alt="ic-search" className="icon-search"/>
                    <input
                        className={clsx("input-search", classNameInput)}
                        placeholder={placeholder}
                        type="text"
                        value={sValue}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        ref={inputRef}
                    />
                    {
                        sValue?.length > 0 &&
                        <button onClick={handleOnClick} className="icon-close">
                            <img src={icXGrey} alt="ic-x"/>
                        </button>
                    }
                </div>
            }
            isShow={!!errorForm?.keyword}
            text={errorForm?.keyword}
        />
    )
}

export default Search
