import {OvertimeSetting} from "components/general-settings/overtime-setting/OvertimeSetting"
import {TABLE_OVERTIME_SETTING} from "configs/general-settings/configTableOvertimeSetting"
import {ACTION_TYPE} from "constants/constant"
import {useEffect, useMemo, useState} from "react"
import {useLocation, useSearchParams} from "react-router-dom"
import {constructOptionsSelect, findOptionSelected, getAllQueryParams, setQueryParams} from "utils/utils"
import {useFetch} from "../../../../services/useFetch";
import {API_OVERTIME_SETTING} from "../../../../services/apiOvertimeSetting";

const FILTER_PARAMS = ['schedule_category_ids', 'grade_ids']

const generateDurations = (min, max) => {
    const list = []
    for (let i = min; i <= max; i++) {
        list.push({label: `${i} Jam`, value: i})
    }

    return list
}

const INITIAL_OPTIONS = {
    schedule_category: [],
    overtimes: [
        {label: 'Hari Kerja', value: true},
        {label: 'Hari Libur', value: false}
    ],
    grades: [],
    min_maks_duration: generateDurations(1, 19),
    location_overtime: [],
    benefits: [],
    multiplier: [
        {label: '1', value: 1},
        {label: '2', value: 2}
    ]
}

const INITIAL_STATE = {
    schedule_category_id: null,
    is_work_day: null,
    grade_ids: [],
    min_duration: null,
    max_duration: null,
    overtime_location_id: null,
    benefit: null,
    multiplier: INITIAL_OPTIONS?.multiplier[0],
}

const getFieldName = {
    schedule_category_id: 'Tipe jam kerja',
    is_work_day: 'Waktu lembur',
    grade_ids: 'Grade',
    min_duration: 'Minimal durasi',
    max_duration: 'Maksimal durasi',
    overtime_location_id: 'Lokasi Lembur',
    benefit: 'Benefit'
}

export const OvertimeSettingContainer = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultQuery = {
        page: 1,
        limit: 20,
        search: ''
    }
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : defaultQuery
    const [form, setForm] = useState(INITIAL_STATE)
    const [actionType, setActionType] = useState(ACTION_TYPE.CREATE)
    const [isShowToast, setIsShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [deleteId, setDeleteId] = useState(null)
    const [queryFilter, setQueryFilter] = useState()
    const [bodyRequest, setBodyRequest] = useState(defaultQuery)
    const [modal, setModal] = useState({
        form: false,
        delete: false,
        filter: false,
        sort: false,
    })
    const [errorForm, setErrorForm] = useState({})
    const isShowMultiplier = form?.benefit?.value?.includes('representative')
    const {data: overtimeSettingList, fetching: getOvertimeSettingList, isLoading} = useFetch(API_OVERTIME_SETTING.OVERTIME_SETTING_LIST)
    const {data: dataScheduleCategory} = useFetch(API_OVERTIME_SETTING.SCHEDULE_CATEGORY_LIST, {}, {isFetching: true})
    const {data: dataGrade} = useFetch(API_OVERTIME_SETTING.GRADE_LIST, {}, {isFetching: true})
    const {data: dataLocation} = useFetch(API_OVERTIME_SETTING.OVERTIME_LOCATION_LIST, {}, {isFetching: true})
    const {data: dataBenefit} = useFetch(API_OVERTIME_SETTING.BENEFIT_LIST, {}, {isFetching: true})
    const {fetching: createOvertimeSetting, isLoading: isCreating} = useFetch(API_OVERTIME_SETTING.CREATE_OVERTIME_SETTING_LIST)
    const {fetching: updateOvertimeSetting, isLoading: isUpdating} = useFetch(API_OVERTIME_SETTING.UPDATE_OVERTIME_SETTING_LIST)
    const {fetching: deleteOvertimeSetting, isLoading: isDeleting} = useFetch(API_OVERTIME_SETTING.DELETE_OVERTIME_SETTING_LIST)

    useEffect(() => {
        const newQuery = {...query}
        const newQueryFilter = {}
        Object.entries(newQuery).forEach(([key, item]) => {
            if (FILTER_PARAMS.includes(key)) {
                const valueOfQuery = item.split(',')
                newQuery[key] = valueOfQuery
                newQueryFilter[key] = valueOfQuery
            }
        })

        const body = {
            ...bodyRequest,
            schedule_category_ids: newQuery.schedule_category_ids || [],
            grade_ids: newQuery.grade_ids || [],
            limit: newQuery.limit,
            page: newQuery.page,
        }

        setQueryFilter(newQueryFilter)
        setBodyRequest(body)
        fetchOvertimeSettingList(body)
    }, [location.search])

    const optionsScheduleCategory = useMemo(() => {
        if (dataScheduleCategory?.data?.length === 0) return []
        return constructOptionsSelect(dataScheduleCategory?.data, 'name', 'id')
    }, [dataScheduleCategory?.data])

    const optionsGrades = useMemo(() => {
        if (dataGrade?.data?.length === 0) return []
        return constructOptionsSelect(dataGrade?.data, 'name', 'id')
    }, [dataGrade?.data])

    const optionsLocation = useMemo(() => {
        if (dataLocation?.data?.result?.length === 0) return []
        return constructOptionsSelect(dataLocation?.data?.result, 'name', 'id')
    }, [dataLocation?.data?.result])

    const optionsBenefit = useMemo(() => {
        if (dataBenefit?.data?.length === 0) return []
        return constructOptionsSelect(dataBenefit?.data, 'value', 'key')
    }, [dataBenefit?.data])

    const options = {
        ...INITIAL_OPTIONS,
        schedule_category: optionsScheduleCategory,
        grades: optionsGrades,
        location_overtime: optionsLocation,
        benefits: optionsBenefit,
    }

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const clearToast = () => {
        setIsShowToast(false)
        setToastMessage('')
    }

    const setShowToast = (message, itemModal) => {
        setModal({...modal, ...itemModal})
        setIsShowToast(true)
        setToastMessage(message)
    }

    const fetchOvertimeSettingList = (item = query) => {
        getOvertimeSettingList(item)
    }

    const onChangeForm = (e) => {
        const {name, value} = e
        setForm({...form, [name]: value})
    }

    const onDelete = () => {
        deleteOvertimeSetting({}, deleteId)
            .then(() => {
                setShowToast('Overtime Setting Deleted !!', {delete: false})
                if (getOvertimeSettingList?.data?.result?.length === 1 && Number(query?.page) !== 1) {
                    onChangeQuery({page: 1})
                } else {
                    fetchOvertimeSettingList(bodyRequest)
                }
            })
    }

    const constructOptionSelected = (item, label, key) => {
        return {label: item?.[label], value: item?.[key]}
    }

    const onActionRow = ({item, actionType}) => {
        const {grades, schedule, location, ...newDataItem} = {
            ...item,
            schedule_category_id: constructOptionSelected(item?.schedule, 'name', 'id'),
            is_work_day: findOptionSelected(INITIAL_OPTIONS?.overtimes, item?.is_work_day),
            grade_ids: constructOptionsSelect(item?.grades || [], 'name', 'id'),
            min_duration: findOptionSelected(INITIAL_OPTIONS?.min_maks_duration, item?.min_duration || 1),
            max_duration: findOptionSelected(INITIAL_OPTIONS?.min_maks_duration, item?.max_duration),
            overtime_location_id: constructOptionSelected(item?.location, 'name', 'id'),
            benefit: constructOptionSelected(item?.benefit, 'value', 'key'),
            multiplier: findOptionSelected(INITIAL_OPTIONS?.multiplier, item?.multiplier)
        }

        switch (actionType) {
            case ACTION_TYPE.CREATE:
                setActionType(ACTION_TYPE.CREATE)
                setForm({...INITIAL_STATE})
                setModal({...modal, form: true})
                setErrorForm({})
                break
            case ACTION_TYPE.EDIT:
                setActionType(ACTION_TYPE.EDIT)
                setForm({...newDataItem})
                setModal({...modal, form: true})
                setErrorForm({})
                break
            default:
                setDeleteId(item?.id)
                setModal({ ...modal, delete: true })
        }
    }

    const constructOvertimeSettingDetail = () => {
        const formCopy = {...form}
        Object.keys(formCopy)?.forEach(key => {
            switch (key) {
                case 'grade_ids':
                    formCopy[key] = formCopy[key]?.map(i => i?.value)
                    break
                case 'min_duration':
                    formCopy[key] = formCopy[key]?.value === 1 ? 0 : formCopy[key]?.value
                    break
                case 'multiplier':
                    formCopy[key] = isShowMultiplier ? formCopy[key]?.value : 1
                    break
                default:
                    formCopy[key] = formCopy[key]?.value ?? formCopy[key]
            }
        })

        return formCopy
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = {}
            const {multiplier, ...formRest} = form
            Object.keys(formRest).forEach(key => {
                if ((key === 'grade_ids' && !form[key]?.length) || !form[key]) {
                    errors[key] = `${getFieldName[key]} tidak boleh kosong`
                } else if ((key === 'max_duration' && form['min_duration']) && (form[key]?.value < form['min_duration']?.value)) {
                    errors[key] = `${getFieldName[key]} kurang dari minimal duration`
                }
            })
            setErrorForm(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmitForm = () => {
        const {id, ...payload} = constructOvertimeSettingDetail()
        validateForm()
            .then(() => {
                if (actionType === ACTION_TYPE.CREATE) {
                    createOvertimeSetting(payload)
                        .then(() => {
                            setShowToast('Overtime Setting Created !!', {form: false})
                            fetchOvertimeSettingList(bodyRequest)
                        })
                } else {
                    updateOvertimeSetting(payload, id)
                        .then(() => {
                            setShowToast('Overtime Setting Updated !!', {form: false})
                            fetchOvertimeSettingList(bodyRequest)
                        })
                }
            })
    }

    const props = {
        configTable: TABLE_OVERTIME_SETTING,
        dataOvertimeLocation: overtimeSettingList?.data,
        query,
        form,
        options,
        modal,
        isShowMultiplier,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        queryFilter,
        onChangeQuery,
        onActionRow,
        onDelete,
        onSetModal: (e) => setModal({...modal, ...e}),
        onChangeForm,
        onSubmitForm,
        isLoadingTable: isLoading,
        isCreating,
        isUpdating,
        isDeleting,
    }

    return (
        <OvertimeSetting {...props} />
    )
}
