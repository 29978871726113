import icLeaveSetting from "assets/images/ic-luggage-03.svg"
import Button from "components/commons/button"
import Datepicker from "components/commons/datepicker"
import Input from "components/commons/input"
import Select from "components/commons/select"
import Switch from "components/commons/switch"
import ErrorMessage from "../../commons/error-message";
import moment from "moment";

export const CreateLeave = (
    {
        options,
        form,
        onChangeForm,
        onSearchEmployee,
        onSubmit,
        isCreating,
        isUpdating,
        errorForm
    }) => {

    return (
        <>
            <div className="p-9">
                <div className="flex items-center gap-3 mb-5">
                    <img className="w-4 h-4" src={icLeaveSetting} alt="leave setting"/>
                    <div className="font-semibold text-md">Cuti Settings</div>
                </div>
                <div className="grid gap-5 lg:grid-cols-2 lg:gap-x-16">
                    <div className="flex gap-3">
                        <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Nama Cuti</div>
                        <div className="flex-1">
                            <Input
                                name="name"
                                placeholder="Isi nama cuti"
                                maxLength={100}
                                value={form?.name}
                                onChange={onChangeForm}
                            />
                            <ErrorMessage className="mt-2" message={errorForm?.name}/>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className={"w-1/3 font-medium mt-2"}>
                            <div className="text-neutral-70">Kuota Cuti</div>
                            <div className={"text-sm italic"}>(kosongkan kolom apabila tidak ada batasan)</div>
                        </div>
                        <Input
                            className="flex-1"
                            name="quota"
                            placeholder="Isi kuota cuti"
                            addonAfter="Hari"
                            value={form?.quota}
                            pattern="^[1-9][0-9]*$"
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Mulai Berlaku</div>
                        <Input
                            className="flex-1"
                            name="min_month_to_start"
                            type="number"
                            placeholder="Minimum mulai berlaku"
                            addonAfter="Bulan"
                            maxLength={2}
                            value={form?.min_month_to_start}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70 required">Berlaku 1/2 Hari</div>
                        <Switch
                            name="is_full_day"
                            checked={form?.is_full_day}
                            onChange={onChangeForm}
                        />
                        <p className="text-neutral-70">{form?.is_full_day ? 'Ya' : 'Tidak'}</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Cuti Dapat Digunakan Tahun Berbeda</div>
                        <Switch
                            name="is_different_year_allowed"
                            checked={form?.is_different_year_allowed}
                            onChange={onChangeForm}
                        />
                        <p className="text-neutral-70">{form?.is_different_year_allowed ? 'Ya' : 'Tidak'}</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Maksimum Request</div>
                        <Input
                            className="flex-1"
                            name="max_request"
                            type="number"
                            placeholder="Isi maksimum request"
                            addonAfter="Kali"
                            maxLength={2}
                            value={form?.max_request}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Minimal Hari Per Request</div>
                        <Input
                            className="flex-1"
                            name="min_day_per_request"
                            type="number"
                            placeholder="Isi minimal hari"
                            addonAfter="Hari"
                            maxLength={2}
                            value={form?.min_day_per_request}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex gap-3">
                        <div className="w-1/3 font-medium text-neutral-70 mt-2">Maximal Hari Per Request</div>
                        <div className="flex-1">
                            <Input
                                className="flex-1"
                                name="max_day_per_request"
                                type="number"
                                placeholder="Isi maksimal hari"
                                addonAfter="Hari"
                                maxLength={2}
                                value={form?.max_day_per_request}
                                onChange={onChangeForm}
                            />
                            <ErrorMessage className="mt-2" message={errorForm?.max_day_per_request}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70 required">Pilih Gender</div>
                        <Select
                            name="gender"
                            className="flex-1"
                            placeholder="Pilih gender"
                            options={options?.gender}
                            value={form?.gender}
                            onChange={onChangeForm}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Pilih Karyawan</div>
                        <Select
                            name="employee_ids"
                            className="flex-1"
                            optionsClassName="max-h-40"
                            placeholder="Pilih karyawan"
                            options={options?.employees}
                            value={form?.employee_ids}
                            searchable
                            isMultiple
                            onChange={onChangeForm}
                            onSearch={(keyword) => onSearchEmployee(keyword)}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70 whitespace-pre-line">{'Batas Waktu \nPengambilan Cuti'}</div>
                        <Datepicker
                            name='max_allowed_date'
                            className="flex-1"
                            value={form?.max_allowed_date}
                            format="YYYY-MM-DD"
                            minDate={moment()}
                            onChange={(e) => onChangeForm({name: "max_allowed_date", value: e})}
                            isClearable
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Tampilkan Pada Aplikasi</div>
                        <Switch
                            name="is_shown_on_application"
                            checked={form?.is_shown_on_application}
                            onChange={onChangeForm}
                        />
                        <p className="text-neutral-70">{form?.is_shown_on_application ? 'Ya' : 'Tidak'}</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 font-medium text-neutral-70">Sertakan Hari Libur Nasional & Mingguan dalam Cuti</div>
                        <Switch
                            name="is_include_day_off"
                            checked={form?.is_include_day_off}
                            onChange={onChangeForm}
                        />
                        <p className="text-neutral-70">{form?.is_include_day_off ? 'Ya' : 'Tidak'}</p>
                    </div>
                </div>
            </div>
            <div
                className="fixed bottom-0 w-[calc(100%-200px)] flex items-center justify-end bg-white border-t py-6 px-14 gap-3">
                <Button className="w-32" title="Simpan" loading={isCreating || isUpdating} onClick={onSubmit}/>
            </div>
        </>
    )
}
