import icAddSquare from "assets/images/id-add-square.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import Drawer from "components/commons/drawer"
import Table from "components/commons/table"
import {ACTION_TYPE} from "../../../constants/constant";
import Toast from "../../commons/toast";
import {formatDateMoment} from "../../../utils/utils";
import Search from "../../commons/search";
import ViewList from "../../commons/view-list";

export const LeaveSetting = (
    {
        configTable,
        dataLeave,
        isShowToast,
        toastMessage,
        previewDetail,
        isLoading,
        isDeleting,
        modal,
        query,
        onChangeQuery,
        onActionRow,
        onDelete,
        onSetModal,
        clearToast,
    }) => {

    const getGenderName = (gender) => {
        switch (gender) {
            case 'male':
                return 'Laki - Laki'
            case 'female':
                return 'Perempuan'
            default:
                return 'Laki - Laki dan Perempuan'
        }
    }

    return (
        <div className={'flex flex-col'}>
            <div className={'flex justify-between items-center gap-3 px-6 py-4'}>
                <div className={"flex gap-3"}>
                    <Search onSearch={(search) => onChangeQuery({page: 1, search})} value={query?.search}/>
                    <ViewList
                        show={modal?.sort}
                        onShow={() => onSetModal({sort: true})}
                        onClose={() => onSetModal({sort: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                </div>
                <Button title="Create Leave" icon={icAddSquare}
                        onClick={() => onActionRow({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                classNameHeader="first:!pl-6 last:!pr-6"
                classNameRow="first:!pl-6 last:!pr-6"
                name={configTable.name}
                data={dataLeave?.result}
                columns={configTable.column}
                size={query?.limit}
                currentPage={query.page}
                totalPage={dataLeave?.total_page}
                totalResult={dataLeave?.total_result}
                onChangePage={(page) => onChangeQuery({page})}
                onClickItem={onActionRow}
                loading={isLoading}
            />
            <Drawer visibility={modal?.preview} onHide={() => onSetModal({preview: false})} title="Preview Cuti">
                <div className="px-6 space-y-5">
                    <div className="flex">
                        <div className="w-44 text-neutral-70 pr-2">Nama Cuti</div>
                        <div className="flex-1 font-medium text-neutral-80">{previewDetail?.name || '-'}</div>
                    </div>
                    <div className="flex">
                        <div className="w-44 text-neutral-70 pr-2">Berlaku Untuk Gender</div>
                        <div className="flex-1 font-medium text-neutral-80">{getGenderName(previewDetail?.gender)}</div>
                    </div>
                    <div className="flex">
                        <div className="w-44 text-neutral-70 pr-2">Minimal Hari Per Request</div>
                        <div
                            className="flex-1 font-medium text-neutral-80">{previewDetail?.min_day_per_request ? `${previewDetail?.min_day_per_request} Hari` : '-'}</div>
                    </div>
                    <div className="flex">
                        <div className="w-44 text-neutral-70 pr-2">Maksimum Hari Per Request</div>
                        <div
                            className="flex-1 font-medium text-neutral-80">{previewDetail?.max_day_per_request ? `${previewDetail?.max_day_per_request} Hari` : '-'}</div>
                    </div>
                    <div className="flex">
                        <div className="w-44 text-neutral-70 pr-2">List Karyawan</div>
                        <div className="flex-1 font-medium text-neutral-80">{
                            previewDetail?.employees?.length ?
                                previewDetail?.employees?.map((item, index) => (
                                    <p key={index}>{item}</p>
                                ))
                                : '-'
                        }</div>
                    </div>
                    <div className="flex">
                        <div className="w-44 text-neutral-70 pr-2">Batas Waktu Pengambilan Cuti</div>
                        <div
                            className="flex-1 font-medium text-neutral-80">{previewDetail?.max_allowed_date ? `${formatDateMoment(previewDetail?.max_allowed_date, 'D MMMM YYYY')}` : '-'}</div>
                    </div>
                </div>
            </Drawer>
            <DeleteModal
                text="Apakah anda yakin ingin menghapus data cuti?"
                show={modal.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
            />
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </div>
    )
}
