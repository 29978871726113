import icAddSquare from "assets/images/id-add-square.svg"
import Button from "components/commons/button"
import DeleteModal from "components/commons/delete-modal"
import FormModal from "components/commons/form-modal"
import Select from "components/commons/select"
import Table from "components/commons/table"
import {ACTION_TYPE} from "../../../constants/constant";
import ErrorMessage from "../../commons/error-message";
import Toast from "../../commons/toast";
import {
    FilterContainer
} from "../../../containers/general-settings/overtime-setting/overtime-setting/FilterContainer";
import ViewList from "../../commons/view-list";

export const OvertimeSetting = (
    {
        configTable,
        dataOvertimeLocation,
        query,
        form,
        options,
        modal,
        isShowMultiplier,
        actionType,
        errorForm,
        isShowToast,
        toastMessage,
        clearToast,
        queryFilter,
        onChangeQuery,
        onActionRow,
        onDelete,
        onSetModal,
        onChangeForm,
        onSubmitForm,
        isLoadingTable,
        isCreating,
        isUpdating,
        isDeleting,
    }) => {

    return (
        <>
            <div className="font-semibold text-md text-neutral-80 p-6">Pengaturan Lembur</div>
            <div className="flex items-center justify-between px-6">
                <div className={"flex gap-3"}>
                    <FilterContainer
                        show={modal.filter}
                        onShow={() => onSetModal({filter: true})}
                        onClose={() => onSetModal({filter: false})}
                        optionsFilter={options}
                        queryFilter={queryFilter}
                        onSubmit={onChangeQuery}
                    />
                    <ViewList
                        show={modal?.sort}
                        onShow={() => onSetModal({sort: true})}
                        onClose={() => onSetModal({sort: false})}
                        value={query?.limit}
                        onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                    />
                </div>
                <Button title="Create Pengaturan Lembur" icon={icAddSquare}
                        onClick={() => onActionRow({actionType: ACTION_TYPE.CREATE})}/>
            </div>
            <Table
                className="my-3"
                classNameHeader="first:!pl-6 last:!pr-6"
                classNameRow="first:!pl-6 last:!pr-6"
                name={configTable.name}
                data={dataOvertimeLocation?.result}
                columns={configTable.column}
                size={dataOvertimeLocation?.limit}
                currentPage={query.page}
                totalPage={dataOvertimeLocation?.total_page}
                totalResult={dataOvertimeLocation?.total_result}
                onChangePage={(page) => onChangeQuery({page})}
                onClickItem={onActionRow}
                loading={isLoadingTable}
            />
            <DeleteModal
                text="Apakah anda yakin ingin menghapus data lokasi lembur?"
                show={modal.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
            />
            {
                modal?.form &&
                <FormModal
                    title={`${actionType === ACTION_TYPE.CREATE ? 'Create' : 'Update'} Pengaturan Lembur`}
                    show={modal.form}
                    width="max-w-[640px]"
                    contentClassName="overflow-auto"
                    onHide={() => onSetModal({form: false})}
                    isLoading={isCreating || isUpdating}
                    onSubmitForm={onSubmitForm}
                >
                    <div className="flex flex-col space-y-5 px-8 py-6">
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Pilih Tipe
                                Kerja
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="schedule_category_id"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih tipe kerja"
                                    options={options?.schedule_category}
                                    value={form?.schedule_category_id}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2"
                                              message={errorForm?.schedule_category_id}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Waktu
                                Lembur
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="is_work_day"
                                    className="flex-1"
                                    placeholder="Pilih waktu lembur"
                                    options={options?.overtimes}
                                    value={form?.is_work_day}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.is_work_day}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Pilih
                                Grade
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="grade_ids"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih grade"
                                    options={options?.grades}
                                    value={form?.grade_ids}
                                    onChange={onChangeForm}
                                    isMultiple
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.grade_ids}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Durasi
                                Minimum
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="min_duration"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih durasi minimum"
                                    options={options?.min_maks_duration}
                                    value={form?.min_duration}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.min_duration}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Durasi
                                Maksimum
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="max_duration"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih durasi maksimum"
                                    options={options?.min_maks_duration}
                                    value={form?.max_duration}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.max_duration}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Pilih
                                Lokasi
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="overtime_location_id"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih lokasi lembur"
                                    options={options?.location_overtime}
                                    value={form?.overtime_location_id}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2"
                                              message={errorForm?.overtime_location_id}/>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="w-1/3 font-medium text-neutral-70 mt-2 required">Benefit
                            </div>
                            <div className="flex-1">
                                <Select
                                    name="benefit"
                                    className="flex-1"
                                    optionsClassName="max-h-40"
                                    placeholder="Pilih benefit"
                                    options={options?.benefits}
                                    value={form?.benefit}
                                    onChange={onChangeForm}
                                />
                                <ErrorMessage className="mt-2" message={errorForm?.benefit}/>
                            </div>
                        </div>
                        <div className={`items-center gap-3 ${isShowMultiplier ? 'flex' : 'hidden'}`}>
                            <div className="w-1/3 font-medium text-neutral-70">Benefit Pengkali</div>
                            <Select
                                name="multiplier"
                                className="flex-1"
                                placeholder="Pilih benefit pengkali"
                                options={options?.multiplier}
                                value={form?.multiplier}
                                onChange={onChangeForm}
                            />
                        </div>
                    </div>
                </FormModal>
            }
            <Toast
                show={isShowToast}
                message={toastMessage}
                onClose={() => clearToast()}
            />
        </>
    )
}
