import {useEffect, useState} from "react";
import {deepClone} from "../../../../utils/utils";
import Filter from "../../../../components/commons/filter";

const FILTER = {
    WORKING_TIME_CATEGORY: 'schedule_category_ids',
}

const QUERY_PARAMS = {
    [FILTER.WORKING_TIME_CATEGORY]: FILTER.WORKING_TIME_CATEGORY,
}

const INITIAL_DATA_FILTER = [
    { label: 'Tipe Jam Kerja', value: FILTER.WORKING_TIME_CATEGORY },
]

const INITIAL_FILTER = {
    [FILTER.WORKING_TIME_CATEGORY]: [],
}

export const FilterContainer = ({
                                            show,
                                            onShow,
                                            onClose,
                                            onSubmit,
                                            optionsScheduleCategory,
                                            queryFilter,
                                            pathName,
                                        }) => {
    const [activeFilter, setActiveFilter] = useState(INITIAL_DATA_FILTER[0])
    const [options, setOptions] = useState()
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [filterCount, setFilterCount] = useState()

    useEffect(() => {
        if (optionsScheduleCategory) {
            const opt = optionsScheduleCategory.map((item) => ({
                name: item.label,
                id: item.value
            }))
            setOptions({...options, [FILTER.WORKING_TIME_CATEGORY]: opt})
        }   
    }, [optionsScheduleCategory])

    useEffect(() => {
        if (queryFilter) {
            const newQueryFilter = deepClone(queryFilter)
            setFilter(newQueryFilter)
            countFilterLength(newQueryFilter)
        }

        setOptions({...options})

    }, [queryFilter, show, pathName])

    const onSelectFilter = (item) => {
        setActiveFilter(item)
    }

    const onChangeOption = (item) => {
        const { name, value, checked } = item
        const arrChecked = filter?.[QUERY_PARAMS[name]] || []
        handleClearOptionsFilter(name)

        if (checked) {
            arrChecked.push(String(value))
        } else {
            const indexRemove = arrChecked.indexOf(String(value))
            arrChecked.splice(indexRemove, 1)
        }
        setFilter({ ...filter, [QUERY_PARAMS[name]]: arrChecked })
    }

    const handleClearOptionsFilter = () => {
        setOptions({ ...options })
    }

    const onSubmitFilter = () => {
        let queryFilter = { ...filter }
        Object.entries(queryFilter).forEach(([key, item]) => {
            if (Array.isArray(queryFilter[key])) queryFilter[key] = item.join(',')
        })
        queryFilter.page = 1
        onSubmit(queryFilter)
        onClose()
    }

    const countFilterLength = (filter) => {
        let totalLength = 0
        for (const key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (Array.isArray(filter[key])) {
                    totalLength += filter[key].length;
                } else {
                    totalLength += 1;
                }
            }
        }
        setFilterCount(totalLength)
    }

    const props = {
        show,
        onShow,
        onClose,
        queryParams: QUERY_PARAMS,
        filterList: INITIAL_DATA_FILTER,
        activeFilter,
        onSelectFilter,
        filter,
        filterCount,
        options,
        onChangeOption,
        onSubmitFilter,
        onClearFilter: () => setFilter(deepClone(INITIAL_FILTER)),
    }

    return(
        <Filter {...props} />
    )
}
