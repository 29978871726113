import {useFetch} from "../../../../../../services/useFetch";
import {API_CONTRACT} from "../../../../../../services/apiContract";
import {useEffect, useState} from "react";
import {constructOptionsSelect, convertArrayToObject, deepClone, formatDateMoment} from "../../../../../../utils/utils";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setContractDetail, setOptions} from "../../../../../../reducers/contractReducer";
import {FormContractContainer} from "../../../../form-contract/FormContractContainer";
import {FORM_ADDENDUM_CONTRACT} from "../../../../../../constants/constant-management-contract";
import {Alert} from "../../../../../../components/commons/alert";

export const AddendumContractContainer = ({type}) => {
    const [searchParams] = useSearchParams();
    const contractId = searchParams.get('contractId')
    const employeeId = searchParams.get('employeeId')
    const dispatch = useDispatch()
    const {contractDetail} = useSelector(state => state.contractReducer)
    const [errorForm, setErrorForm] = useState({})
    const [form, setForm] = useState([])
    const {fetching: getContractDetailApi} = useFetch(API_CONTRACT.DETAIL_CONTRACT)
    const {fetching: getJobTitleByCompanyApi} = useFetch(API_CONTRACT.LIST_JOB_TITLE)
    const {fetching: addendumContractApi} = useFetch(API_CONTRACT.ADDENDUM_CONTRACT)
    const {fetching: getGradeByJobTitleApi} = useFetch(API_CONTRACT.LIST_GRADE)
    const {fetching: getConstantApi} = useFetch(API_CONTRACT.CONSTANT)

    useEffect(() => {
        getConstantApi()
            .then(result => {
                let ptkp_status = constructOptionsSelect(result.data.ptkp_status)
                dispatch(setOptions({ptkp_status}))
            })
    }, []);

    useEffect(() => {
        getContractDetailApi({}, contractId)
            .then(res => {
                let company = constructOptionsSelect([res.data.company], 'name', 'id')
                let job_title = constructOptionsSelect([res.data.job_title], 'name', 'id')
                let grade = constructOptionsSelect([res.data.grade], 'name', 'id')
                let ptkp_status = constructOptionsSelect([res.data.ptkp_status])
                let type = constructOptionsSelect([res.data.type])
                dispatch(setContractDetail({
                    ...res.data,
                    addendum_number: null,
                    addendum_start_date: null,
                    company: company[0],
                    job_title: job_title[0],
                    grade: grade[0],
                    ptkp_status: ptkp_status[0],
                    type: type[0]
                }))
                getJobTitle(company[0].value)
                getGrade(job_title[0].value)
                constructAllowance(res.data.allowances)
            })
    }, []);

    const constructAllowance = (allowances) => {
        let copyForm = deepClone(FORM_ADDENDUM_CONTRACT)
        let copyFormSalary = deepClone(FORM_ADDENDUM_CONTRACT[3].form)
        copyForm[3].form = copyFormSalary.concat(allowances)
        setForm(copyForm)
    }

    const getJobTitle = (company_id) => {
        getJobTitleByCompanyApi({company_id}, employeeId)
            .then(result => {
                dispatch(setOptions({job_title: constructOptionsSelect(result.data, 'title', 'id', 'description')}))
            })
    }

    const getGrade = (job_title_id) => {
        getGradeByJobTitleApi({job_title_id})
            .then(result => {
                dispatch(setOptions({grade: constructOptionsSelect(result.data, 'name', 'id')}))
            })
    }

    const onSubmitForm = () => {
        let requestBody = {
            ...contractDetail,
            is_interim: type === 'interim',
            company_id: contractDetail.company.value,
            job_title_id: contractDetail.job_title.value,
            grade_id: contractDetail.grade.value,
            type: contractDetail.type.value,
            ptkp_status: contractDetail.ptkp_status.value,
            end_date: formatDateMoment(contractDetail.end_date, 'YYYY-MM-DD'),
            addendum_start_date: formatDateMoment(contractDetail.addendum_start_date, 'YYYY-MM-DD'),
            addendum_end_date: formatDateMoment(contractDetail.end_date, 'YYYY-MM-DD'),
        }

        addendumContractApi(requestBody, contractId)
            .then(res => {
                Alert({
                    icon: 'success',
                    title: 'Success',
                    message: 'Addendum Contract berhasil!',
                })
                window.location.href = `/management-contract/contract-employee/employee-detail?contractId=${contractId}`
            })
            .catch(err => {
                if (err.code === '422') {
                    setErrorForm(convertArrayToObject(err.data))
                }
            })
    }

    let props = {
        onSubmitForm,
        activity: type === 'addendum' ? 'addendum' : 'addendum (interim)',
        form,
        errorForm
    }

    return (
        <FormContractContainer {...props} />
    )
}
