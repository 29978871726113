import {EmployeeListContainer} from "../containers/employee/employee-list/EmployeeListContainer";
import {EmployeeImportContainer} from "../containers/employee/employee-import/EmployeeImportContainer";
import {EmployeeDetailContainer} from "../containers/employee/employee-detail/EmployeeDetailContainer";
import {AddEmployeeContainer} from "../containers/employee/employee-add/AddEmployeeContainer";
import {
    RegisteredEmployeeContainer
} from "../containers/management-contract/create-contract/RegisteredEmployeeContainer";
import {CreateContractContainer} from "../containers/management-contract/create-contract/CreateContractContainer";
import {
    CreateContractImportContainer
} from "../containers/management-contract/create-contract/create-contract-import/CreateContractImportContainer";
import {ContractEmployeeContainer} from "../containers/management-contract/contract-employee/ContractEmployeeContainer";
import {
    ContractEmployeeDetailContainer
} from "../containers/management-contract/contract-employee/contract-employee-detail/ContractEmployeeDetailContainer";
import {
    GradeSettingContainer
} from "../containers/organization-structure-management/grade-setting/GradeSettingContainer";
import {
    JobTitleSettingsContainer
} from "../containers/organization-structure-management/job-title-settings/JobTitleSettingsContainer";
import {
    WorkUnitSettingContainer
} from "../containers/organization-structure-management/work-unit-setting/WorkUnitSettingContainer";
import {
    OrganizationalStructureContainer
} from "../containers/organization-structure-management/organizational-structure/OrganizationalStructureContainer";
import {
    AccountActivationContainer
} from "../containers/management-account/account-activation/AccountActivationContainer";
import {RoleAccessContainer} from "../containers/management-account/role-access/RoleAccessContainer";
import {CreateRoleContainer} from "../containers/management-account/create-role/CreateRoleContainer";
import {WorkTimeContainer} from "../containers/work-settings/work-time/WorkTimeContainer";
import {WorkTimeFormContainer} from "../containers/work-settings/work-time-form/WorkTimeFormContainer";
import {DayOffListContainer} from "../containers/work-settings/day-off-list/DayOffListContainer";
import {ApprovalSettingContainer} from "../containers/matrix-approval/approval-setting/ApprovalSettingContainer";
import {ActivitySettingContainer} from "../containers/matrix-approval/activity-setting/ActivitySettingContainer";
import {
    PncApproverSettingContainer
} from "../containers/matrix-approval/pnc-approver-setting/PncApproverSettingContainer";
import {CompanySettingsContainer} from "../containers/company-settings/CompanySettingsContainer";
import {
    ModifyContractContainer
} from "../containers/management-contract/contract-employee/contract-employee-detail/modify-contract/ModifyContractContainer";
import {AdditionalIncentiveContainer} from "../containers/additional-incentive/AdditionalIncentiveContainer";
import {ShiftingImportContainer} from "../containers/request-shifting/shifting-import/ShiftingImportContainer";
import {ShiftingListContainer} from "../containers/request-shifting/shifting-list/ShiftingListContainer";
import {AttendanceListContainer} from "../containers/attendance/attendance-list/AttendanceListContainer";
import {ShiftingDetailContainer} from "../containers/request-shifting/shifting-detail/ShiftingDetailContainer";
import {AttendanceDetailContainer} from "../containers/attendance/attendance-list/AttendanceDetailContainer";
import {RequestLeaveContainer} from "containers/request-leave/RequestLeaveContainer";
import {RequestListContainer} from "../containers/attendance/request-list/RequestListContainer";
import {OvertimeContainer} from "containers/overtime/OvertimeContainer";
import {RemoteWorkingContainer} from "containers/remote-working/RemoteWorkingContainer";
import {LeaveSettingContainer} from "containers/general-settings/leave-setting/LeaveSettingContainer";
import {CreateLeaveContainer} from "containers/general-settings/leave-setting/CreateLeaveContainer";
import {RemoteWorkingSettingContainer} from "containers/general-settings/remote-working-setting/RemoteWorkingSettingContainer";
import {GeneralOvertimeSettingContainer} from "containers/general-settings/overtime-setting/GeneralOvertimeSettingContainer";
import {HRISAnnouncementContainer} from "../containers/general-settings/hris-announcement/HRISAnnouncementContainer";
import {HRISAnnouncementFormContainer} from "../containers/general-settings/hris-announcement-form/HRISAnnouncementFormContainer";
import {SettingComponentContainer} from "../containers/payroll/setting-component/SettingComponentContainer";
import {PayrollListContainer} from "../containers/payroll/payroll-list/PayrollListContainer";
import {IncentiveContainer} from "../containers/payroll/incentive/IncentiveContainer";
import {PercentageFormContainer} from "../containers/payroll/incentive/PercentageFormContainer";
import {PayrollDetailContainer} from "../containers/payroll/payroll-detail/PayrollDetailContainer";
import {CreatePayrollContainer} from "../containers/payroll/create-payroll/CreatePayrollContainer";
import {GeneralCreatePayrollContainer} from "../containers/payroll/create-payroll/GeneralCreatePayrollContainer";
import {SettingTypeAllowanceContainer} from "../containers/organization-structure-management/setting-type-allowance/SettingTypeAllowanceContainer";
import {AppVersionContainer} from "../containers/app-version/AppVersionContainer";
import {AnalyticsListContainer} from "../containers/attendance/analytics/AnalyticsListContainer";

export const ROUTE_LIST = [
    {
        path: '/employee/active-employee',
        component: <EmployeeListContainer/>
    },
    {
        path: '/employee/former-employee',
        component: <EmployeeListContainer/>
    },
    {
        path: '/employee/prospective-employee',
        component: <EmployeeListContainer/>
    },
    {
        path: '/employee/prospective-employee/import-employee',
        component: <EmployeeImportContainer/>
    },
    {
        path: '/employee/:type/user-detail',
        component: <EmployeeDetailContainer/>
    },
    {
        path: '/employee/prospective-employee/add-employee',
        component: <AddEmployeeContainer/>
    },
    {
        path: '/management-contract/create-contract',
        component: <RegisteredEmployeeContainer/>
    },
    {
        path: '/management-contract/create-contract/:employee',
        component: <CreateContractContainer/>
    },
    {
        path: '/management-contract/create-contract/import-contract',
        component: <CreateContractImportContainer/>
    },
    {
        path: '/management-contract/create-contract/import-contract',
        component: <CreateContractImportContainer/>
    },
    {
        path: '/management-contract/contract-employee',
        component: <ContractEmployeeContainer/>
    },
    {
        path: '/management-contract/contract-employee/employee-detail',
        component: <ContractEmployeeDetailContainer/>
    },
    {
        path: '/management-contract/contract-employee/:employee',
        component: <ModifyContractContainer/>
    },
    {
        path: '/management-structure-organization/grade-settings',
        component: <GradeSettingContainer/>
    },
    {
        path: '/management-structure-organization/grade-settings/setting-type-allowance',
        component: <SettingTypeAllowanceContainer/>
    },
    {
        path: '/management-structure-organization/job-title-settings',
        component: <JobTitleSettingsContainer/>
    },
    {
        path: '/management-structure-organization/works-unit-settings',
        component: <WorkUnitSettingContainer/>
    },
    {
        path: '/management-structure-organization/structure-organization',
        component: <OrganizationalStructureContainer/>
    },
    {
        path: '/management-account/account-activation',
        component: <AccountActivationContainer/>
    },
    {
        path: '/management-account/role-access',
        component: <RoleAccessContainer/>
    },
    {
        path: '/management-account/role-access/create-role',
        component: <CreateRoleContainer/>
    },
    {
        path: '/management-account/role-access/update-role',
        component: <CreateRoleContainer/>
    },
    {
        path: '/management-account/account-activation/update-role',
        component: <CreateRoleContainer/>
    },
    {
        path: '/work-settings/work-time',
        component: <WorkTimeContainer/>
    },
    {
        path: '/work-settings/work-time/:method',
        component: <WorkTimeFormContainer/>
    },
    {
        path: '/work-settings/day-off-list',
        component: <DayOffListContainer/>
    },
    {
        path: '/matrix-approval/approval-setting',
        component: <ApprovalSettingContainer/>
    },
    {
        path: '/matrix-approval/activity-setting',
        component: <ActivitySettingContainer/>
    },
    {
        path: '/matrix-approval/pnc-approver-setting',
        component: <PncApproverSettingContainer/>
    },
    {
        path: '/settings-perusahaan',
        component: <CompanySettingsContainer/>
    },
    {
        path: '/additional-incentive-request',
        component: <AdditionalIncentiveContainer />
    },
    {
        path: '/shifting',
        component: <ShiftingListContainer/>
    },
    {
        path: '/shifting/employee',
        component: <ShiftingDetailContainer/>
    },
    {
        path: '/shifting/import-shifting',
        component: <ShiftingImportContainer/>
    },
    {
        path: '/attendance/attendance-list',
        component: <AttendanceListContainer/>
    },
    {
        path: '/attendance/attendance-list/detail-attendance',
        component: <AttendanceDetailContainer/>
    },
    {
        path: '/attendance/request-list',
        component: <RequestListContainer/>
    },
    {
        path: '/attendance/analytics',
        component: <AnalyticsListContainer/>
    },
    {
        path: '/leave-request',
        component: <RequestLeaveContainer/>
    },
    {
        path: '/overtime-request',
        component: <OvertimeContainer/>
    },
    {
        path: '/remote-working-request',
        component: <RemoteWorkingContainer/>
    },
    {
        path: '/general-settings/leave-setting',
        component: <LeaveSettingContainer/>
    },
    {
        path: '/general-settings/leave-setting/create-leave',
        component: <CreateLeaveContainer/>
    },
    {
        path: '/general-settings/leave-setting/update-leave',
        component: <CreateLeaveContainer/>
    },
    {
        path: '/general-settings/remote-working-setting',
        component: <RemoteWorkingSettingContainer/>
    },
    {
        path: '/general-settings/overtime-setting',
        component: <GeneralOvertimeSettingContainer/>
    },
    {
        path: '/general-settings/hris-announcement',
        component: <HRISAnnouncementContainer/>
    },
    {
        path: '/general-settings/hris-announcement/:method',
        component: <HRISAnnouncementFormContainer/>
    },
    {
        path: '/payroll/settings-komponen-payroll',
        component: <SettingComponentContainer/>
    },
    {
        path: '/payroll/payroll-list',
        component: <PayrollListContainer/>
    },
    {
        path: '/payroll/payroll-list/:detail',
        component: <PayrollDetailContainer/>
    },
    {
        path: '/payroll/create-payroll',
        component: <GeneralCreatePayrollContainer/>
    },
    {
        path: '/payroll/create-payroll/:step',
        component: <CreatePayrollContainer/>
    },
    {
        path: '/payroll/incentive',
        component: <IncentiveContainer/>
    },
    {
        path: '/payroll/incentive/:type',
        component: <IncentiveContainer/>
    },
    {
        path: '/payroll/incentive/percentage-ketercapaian',
        component: <PercentageFormContainer/>
    },
    {
        path: '/versioning',
        component: <AppVersionContainer/>
    },
]
